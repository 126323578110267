import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import Message from "@/components/Message";
import Loader from "../components/Loader";
import { getErrorMessage } from "./api/fetch";

// RunFunc is the function returned by the hook which you can wrap your API call in
// e.g. run(getCourses())
type RunFunc<T> = (
  promise: Promise<T>,
  onSuccess?: (data: T) => boolean,
  onFailure?: (error: unknown) => void,
) => void;

// ReturnValue is the return value of the hook
type ReturnValue<T> = {
  loading: boolean;
  error: string;
  run: RunFunc<T>;
  resp: T | undefined;
};

type Options = {
  loading?: boolean;
};

// useRequest handles setting loading/error states and calling onSuccess/onFailure callbacks
// it takes a type T, which is usually inferred by the first argument
// e.g. useRequest(getCourses) will return a ReturnValue<Course[]>
export const useRequest = <T extends object>(
  func: (...args: any[]) => Promise<T>,
  options?: Options,
): ReturnValue<T> => {
  const [resp, setResponse] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(!!options?.loading);
  const [error, setError] = useState("");

  // prevent calling setters if the component using this hook is unmounted
  // this can happen if e.g. onSuccess callback causes page navigation
  const isMountedRef = useRef(true);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // we could just return the promise from run(), but using onSuccess and onFailure callbacks
  // allows us to react *before* the loading/errors states change - this is mostly useful if
  // we want to e.g. redirect before a re-render happens
  const run = useCallback<RunFunc<T>>(async (promise, onSuccess, onFailure) => {
    setResponse(undefined);
    setLoading(true);
    setError("");

    try {
      const resp = await promise;

      let newLoadingState = false;
      if (onSuccess) {
        // if onSuccess returns true, we keep loading=true
        // while a redirect happens, to avoid a flash of the
        // form in its not-loading state (which could concievably allow duplicate submissions)
        newLoadingState = !!onSuccess(resp);
      }
      if (isMountedRef.current) {
        setResponse(resp);
        setLoading(newLoadingState);
      }
    } catch (error) {
      if (onFailure) {
        onFailure(error);
      }
      if (isMountedRef.current) {
        setError(getErrorMessage(error));
        setLoading(false);
      }
    }
  }, []);

  return { loading, error, run, resp };
};

// component version that handles default loading and error states
export const WithRequest = <F extends (...args: any[]) => Promise<any>>({
  request,
  requestArgs,
  children,
  loader,
}: {
  request: F;
  requestArgs?: Parameters<F>;
  children: (resp: Awaited<ReturnType<F>>, reload: () => void) => ReactNode;
  loader?: ReactNode;
}) => {
  const { loading, error, run, resp } = useRequest(request, {
    loading: true,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: the spread version of requestArgs is better for our dependency array
  const loadFunc = useCallback(() => {
    run(request(...(requestArgs ?? [])));
  }, [run, request, ...(requestArgs ?? [])]);

  // initial load
  useEffect(() => {
    loadFunc();
  }, [loadFunc]);

  if (loading) {
    return loader ?? <Loader />;
  }

  if (error) {
    return <Message error>{error}</Message>;
  }

  if (!resp) {
    return <Message warning>No Response</Message>;
  }

  return children(resp, loadFunc);
};
